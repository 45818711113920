<template>
  <div class="pre-body pre-body-md">
    <div class="pre-subhead pre-subhead-border centered">
      <div class="pre-divider"></div>
      <div class="pre-subhead--copy">Prepare for Class</div>
      <div class="pre-divider"></div>
    </div>

    <div class="pre-pad"></div>

    <div class="pre-three-col">
      <div class="pre-col">
        <lazy-img class="pre-image" src="/assets/images/prepare.jpg" alt="" />
      </div>
      <div class="pre-col">
        <lazy-img class="pre-image" src="/assets/images/prepare.jpg" alt="" />
      </div>
      <div class="pre-col">
        <lazy-img class="pre-image" src="/assets/images/prepare.jpg" alt="" />
      </div>
    </div>

    <div class="pre-pad"></div>

    <div class="pre-subhead centered">
      We recommend you assemble your equipment and protective clothing to ensure
      you are fully prepared to face any and all paranormal entities.
    </div>

    <div class="pre-pad"></div>
    <div class="pre-copy centered">
      <p>Visit our supplies store for appropriate apparel!</p>
    </div>

    <div class="pre-pad"></div>
    <div class="pre-btns centered">
      <pnw-button variant="primary" size="xl" @click="gotoSuppliesStore">
        Supplies store
      </pnw-button>
    </div>

    <div class="pre-pad"></div>

    <div class="pre-divider my-5"></div>

    <div class="pre-pad"></div>

    <template v-if="isAuthenticated">
      <img class="pre-icon" src="/assets/images/profile.png" alt="" />

      <div class="pre-subhead centered"></div>

      <div class="pre-pad"></div>

      <div class="pre-form--group">
        <div class="pre-btns centered">
          <pnw-button variant="primary" size="xl" @click="gotoProfile">
            Your Profile
          </pnw-button>
        </div>
      </div>

      <div class="pre-pad"></div>

      <div class="pre-divider"></div>

      <div class="pre-pad"></div>
    </template>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import PnwButton from "../components/PnwButton.vue";
import routerItems from "../constants/routerItems";
export default {
  components: { PnwButton },
  name: "Shop",
  methods: {
    gotoProfile() {
      this.$router.push({ name: routerItems.PROFILE });
    },
    gotoSuppliesStore() {
      window.open(
        "https://shop.ghostbusters-paranormal.institute",
        "_blank"
      );
    },
  },
  computed: {
    ...mapGetters({
      isAuthenticated: "isAuthenticated",
    }),
  },
};
</script>
